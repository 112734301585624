<template>
  <div class="header_container">
    <el-row>
      <!-- 折叠图标 -->
      <el-col style="flex: 1;margin: 0 0 0 10px">
        <i style="font-size: 28px;cursor:pointer" :class="isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'" @click="handleCollapse" />
      </el-col>
      <!-- 导航菜单 -->
      <el-col style="flex: 17">
        <el-breadcrumb separator="/">
          <!-- <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item> -->
          <el-breadcrumb-item :to="{ name: activeItem.pathName }">{{ activeItem.name }}</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- <el-button @click="all">全屏</el-button> -->
      </el-col>
      <!-- 个人信息 -->
      <el-col style="flex: 3">
        <span>{{ hello }}</span>&nbsp;
        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            <el-button type="info" size="mini">
              <span style="color: #000; font-size: 16px">{{ $store.state.userInfo.name }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logOut">退出登录</el-dropdown-item>
            <el-dropdown-item command="changePwd">修改密码</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>

    <!-- 浏览记录 -->
    <el-row>
      <el-tag size="small" v-for="tag in $store.state.navGoList" :key="tag.name" :class="tag.active ? 'active_nav' : ''" :closable="!tag.active" @close="handleTagClose(tag)" @click="handleGo(tag)">
        {{tag.name}}
      </el-tag>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeItem: {}
    }
  },
  props: {
    isCollapse: {
      type: Boolean
    }
  },
  created() {
    setTimeout(this.logOut, 300000)
  },
  updated() {
    const isFind = this.$store.state.navGoList.find(item => item.active === true)
    this.activeItem = isFind
  },
  methods: {
    // 浏览器全屏
    all() {
      document.documentElement.requestFullscreen()
    },
    logOut() {
      this.$router.push({ name: 'Login' })
      window.sessionStorage.clear()
      this.$notify.success({ message: '退出登录!', title: '成功', duration: 800 })
    },
    changePwd() {
      console.log('修改密码')
    },
    handleCommand(command) {
      switch (command) {
        case 'logOut':
          this.logOut()
          break;
        case 'changePwd':
          this.changePwd()
          break;
        default:
          break;
      }
    },
    handleCollapse() {
      this.$emit('changeCollapse')
    },
    // 删除导航标签
    handleTagClose(tag) {
      if (tag.pathName === "MemberInfo") return this.$notify({
        title: '注意',
        message: '该记录禁止删除!',
        type: 'warning'
      });
      this.$store.commit('DELETE_NAV_ITEM', tag)
    },
    // 点击tage跳转
    handleGo(tag) {
      if (this.$route.name === tag.pathName) return
      this.$store.commit('HREF_NAV', tag)
      this.$router.push({ name: tag.pathName })
    }
  },
  computed: {
    hello() {
      const hour = new Date().getHours();
      if (hour <= 0 && hour > 6) {
        return '凌晨好'
      } else if (hour >= 6 && hour < 8) {
        return '早上好'
      } else if (hour >= 8 && hour < 12) {
        return '上午好'
      } else if (hour >= 12 && hour < 13) {
        return '中午好'
      } else if (hour >= 13 && hour < 18) {
        return '下午好'
      } else if (hour >= 18 && hour < 24) {
        return '晚上好'
      } else {
        return '你好'
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler: function (newVal) {
        // console.log('路由新参数', newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header_container {
  width: 100%;
  height: 100%;
  .el-row {
    width: 100%;
    height: 50%;
    display: flex;
    // justify-content: flex-start;
    justify-content: flex-start;
    align-items: center;
    .el-col {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    .el-tag {
      margin: 0 5px;
      cursor: pointer;
      border: none;
    }
  }
}

.active_nav {
  // transform: scale(1.2);
  background-color: #42b983;
  color: #fff;
  border-color: #42b983;
  transform: scale(1.1);
}
.active_nav::before {
  content: "";
  background: #fff;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  margin-right: 2px;
}
</style>
