<template>
  <div class="home_container">
    <el-container>
      <el-aside :style="{width: isCollapse ? 'auto' : '200px'}">
        <!-- 左侧导航栏菜单 -->
        <el-menu :collapse="isCollapse" collapse-transition unique-opened router :default-active="activeMenu" class="el-menu-vertical-demo" background-color="rgb(48, 65, 86)" text-color="rgb(191, 203, 217)" active-text-color="rgb(64, 158, 255)">
          <SidebarItem v-for="item in $store.state.userInfo.menus" :key="item.path" :item="item" :collapse="isCollapse" />
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <Header @changeCollapse="changeCollapse" :isCollapse="isCollapse" />
        </el-header>
        <el-main>
          <!-- 对应子组件渲染 -->
          <transition name="el-zoom-in-center">
            <router-view />
          </transition>

          <!-- <transition>
            <router-view />
          </transition> -->

          <!-- <el-collapse-transition>
            <router-view />
          </el-collapse-transition> -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import SidebarItem from '../layout/sidebar/Sidebar.vue'
import Header from '../layout/header/Header.vue'
export default {
  data() {
    return {
      // 折展状态
      isCollapse: false,
      // 选中的菜单
      activeMenu: ''
    }
  },
  created() {
    this.activeMenu = this.$route.path.replace('/', '')
    this.isCollapse = window.localStorage.getItem('collapseStatus') === 'true'
  },
  methods: {
    changeCollapse() {
      this.isCollapse = !this.isCollapse
      window.localStorage.setItem('collapseStatus', this.isCollapse)
    }
  },
  watch: {
    '$route': function (newVal) {
      this.activeMenu = newVal.path.replace('/', '')
    }

  },
  components: { SidebarItem, Header }
}
</script>

<style lang="scss" scoped>
.home_container {
  width: 100%;
  height: 100%;
}
.el-header,
.el-footer {
  margin: 0;
  padding: 0;
  background-color: #fff;
  opacity: 0.7;
  color: #333;
  // text-align: center;
  // line-height: 60px;
}

.el-aside {
  background-color: #d3dce6;
  opacity: 0.9;

  color: #333;
  text-align: center;
  // line-height: 200px;
}

.el-main {
  background-color: #e9eef3;
  opacity: 0.8;

  color: #333;
  // text-align: center;
  // line-height: 160px;
}

body > .el-container {
  // margin-bottom: 40px;
}

.el-header {
  height: 80px !important;
  // box-shadow: 0 2px 12px 0 rgba(112, 86, 86, 0.445);
  border-bottom: 2px solid rgba(255, 0, 0, 0.274);
}

// .el-container:nth-child(5) .el-aside,
// .el-container:nth-child(6) .el-aside {
//   line-height: 260px;
// }

// .el-container:nth-child(7) .el-aside {
//   line-height: 320px;
// }
/deep/.el-menu-vertical-demo.el-menu {
  height: 100%;
}
</style>
