<template>
  <div>
    <!-- 单菜单 -->
    <template v-if="!item.children || !item.children.length">
      <el-menu-item
        :index="item.path"
        @click="navGo()"
      >
        <i :class="item.icons"></i> &nbsp;
        <span
          slot="title"
          class="text_sty"
        >{{ item.text }}</span>
      </el-menu-item>
    </template>
    <!-- 多菜单 -->
    <template v-else>
      <el-submenu :index="item.path">
        <template slot="title">
          <i :class="item.icons"></i>&nbsp;
          <span
            slot="title"
            class="text_sty"
          >{{ item.text }}</span>
        </template>
        <el-menu-item
          v-for="childItem in item.children"
          :key="childItem.path"
          :index="childItem.path"
        >
          {{ childItem.text }}
        </el-menu-item>
      </el-submenu>
    </template>

  </div>
</template>

<script>
export default {
  name: 'sidebar',
  props: {
    item: {
      type: Object,
      required: true,
    },
    collapse: {
      type: Boolean,
    },
  },
  methods: {
    navGo() {
      this.$store.commit('SET_NAVGO_LIST', this.item)
    },
  },
}
</script>

<style  scoped>
.text_sty {
  font-size: 16px;
}
</style>
